import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router';

import NewTable from '../../components/Table/Table';
import Router from '../../routes/router';
import addIcon from '../../assets/images/add-circle.svg';
import iconSave from '../../assets/images/icon-save.svg';
import iconDownload from '../../assets/images/download-btn.svg';

import { TableRow as TableRowInter } from '../../components/Table/types';
import { CountriesListPage, CountriesListModel, CountrySummary, Filters, CountryOrder, CountryMainProps } from './countryTypes';

import RedButton from '../../components/base/Buttons/RedButton';
import SearchField from '../../components/base/SearchField/SearchField';
import SuccessModal from '../../components/base/SuccessModal/SuccessModal';
import ExportButton from '../../components/base/Buttons/ExportButton';
import DropdownSingleSelection from '../../components/base/Dropdowns/DropdownSingleSelection';
import {
    exportCountryCSV,
    disabledEnabledDropdownValues,
    setTableRows,
    getHeaderData,
    saveHandler
} from './countryHelpers';
import { COUNTRIES_LIST_MODEL, EDIT_COUNTRIES } from './countryQueries';
import * as messages from '../../utils/Constant/messages';

const ManageCountries: React.FC<CountryMainProps> = () => {
    const params = useParams<CountryMainProps>();
    const perpage = 50;
    const isCountryCreated = params.isCountryCreated ?? 'false';
    const [selectedBenchmarking, setBenchmarking] = useState<string>('All');
    const [selectedStatus, setStatus] = useState<string>('All');
    const [allSelectedBenchmarking, setAllSelectedBenchmarking] = useState<number[]>([]);
    const [allSelectedCountries, setAllSelectedCountries] = useState<number[]>([]);
    const [allInteractedBenchmarking, setAllInteractedBenchmarking] = useState<number[]>([]);
    const [allInteractedCountries, setAllInteractedCountries] = useState<number[]>([]);
    const [page, setPage] = useState<number>(0);
    const [tableRow, setRow] = useState<TableRowInter[]>();
    const [filters, setFilters] = useState<Filters>({
        countryEnabled: undefined,
        benchmarkingEnabled: undefined
    });
    const [order, setOrder] = useState<CountryOrder|undefined>({field:'name',isAsc:true});
    const [searchText, setSearchText] = useState<string>('');
    const [countriesListPage, setCountriesListPage] = useState<CountriesListPage>();
    const [exportSpinner, setExportSpinner] = useState<boolean>(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [isModalInternal, setIsModalInternal] = useState(true);

    useEffect(() => {
            if(isCountryCreated === 'true') {
                setOpenSuccessModal(true)
                setIsModalInternal(false)
            }
        }, [isCountryCreated]);

    const [getCountries, { loading: loadingCountries }] = useLazyQuery<CountriesListModel>(COUNTRIES_LIST_MODEL, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            setCountriesListPage(res.countrySummaries);

            const allBenchmarking = res.countrySummaries.items
                .filter(
                    (item: CountrySummary) => item.benchmarkingEnabled && !allInteractedBenchmarking.includes(item.id)
                )
                .map((item: CountrySummary) => item.id);
            const allUniqueSelectedBenchmarkingIds = Array.from(
                new Set([...allBenchmarking, ...allSelectedBenchmarking])
            );
            setAllSelectedBenchmarking(allUniqueSelectedBenchmarkingIds);
            const allSelected = res.countrySummaries.items
                .filter((item: CountrySummary) => item.countryEnabled && !allInteractedCountries.includes(item.id))
                .map((item: CountrySummary) => item.id);
            const allUniqueSelectedIds = Array.from(new Set([...allSelected, ...allSelectedCountries]));
            setAllSelectedCountries(allUniqueSelectedIds);
        },
        onError: (err) => {
            console.log(err);
        }
    });

    const createSortHandler = (property: string) => {
        if(order && order.field===property)
        {
            setOrder({field:property,isAsc:!order.isAsc});
        }
        else{
            setOrder({field:property,isAsc:true});
        }
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleToggleBenchmarkingChange = (cellID:number) => {
        if (allSelectedBenchmarking.includes(cellID)) {
            const arrWithoutCell = allSelectedBenchmarking.filter((selBench: number) => selBench !== cellID);
            setAllSelectedBenchmarking(arrWithoutCell);
        } else {
            setAllSelectedBenchmarking([...allSelectedBenchmarking, cellID]);
        }
        if (!allInteractedBenchmarking.includes(cellID)) {
            setAllInteractedBenchmarking([...allInteractedBenchmarking, cellID]);
        }
    };

    const handleToggleActivateChange = (cellID:number) => {
        if (allSelectedCountries.includes(cellID)) {
            const arrWithoutCell = allSelectedCountries.filter((selCoun: number) => selCoun !== cellID);
            setAllSelectedCountries(arrWithoutCell);
        } else {
            setAllSelectedCountries([...allSelectedCountries, cellID]);
        }

        if (!allInteractedCountries.includes(cellID)) {
            setAllInteractedCountries([...allInteractedCountries, cellID]);
        }
    };

    const countriesSearchCallback = (value: string) => {
        setSearchText(value);
        setPage(0);
    };

    const getFilters = () => {
        const filter: Filters = {
            countryEnabled: undefined,
            benchmarkingEnabled: undefined
        };

        filter.countryEnabled = selectedStatus === 'Disabled' ? false : selectedStatus === 'Enabled' ? true : undefined;
        filter.benchmarkingEnabled =
            selectedBenchmarking === 'Disabled' ? false : selectedBenchmarking === 'Enabled' ? true : undefined;

        setPage(0);
        setFilters(filter);
    };

    useEffect(() => {
        // if (loading) {
        //     return;
        // }

        getCountries({
            variables: {
                filters,
                search: searchText,
                pagination: {
                    order:order?order:undefined,
                    skip: page * perpage,
                    take: perpage
                }
            }
        });
    }, [page, filters, searchText,order]);

    useEffect(() => {
        if (!countriesListPage) {
            return;
        }

        const rows = setTableRows(
            allSelectedBenchmarking,
            handleToggleBenchmarkingChange,
            allSelectedCountries,
            handleToggleActivateChange,
            countriesListPage
        );

        setRow(rows);
    }, [countriesListPage, allSelectedCountries, allSelectedBenchmarking, openSuccessModal]);

    useEffect(() => {
        getFilters();
    }, [selectedStatus, selectedBenchmarking]);

    const headerData = getHeaderData(createSortHandler);

    const tablePagination = {
        rowsPerPage: perpage,
        currentPage: page,
        handleChangePage,
        totalCount: countriesListPage && countriesListPage.total
    };

    const [editCountries] = useMutation(EDIT_COUNTRIES);

    const saveChanges = () => {
        saveHandler(
            allInteractedBenchmarking,
            allInteractedCountries,
            allSelectedBenchmarking,
            allSelectedCountries,
            editCountries,
            setOpenSuccessModal,
            setAllInteractedBenchmarking,
            setAllInteractedCountries,
            setIsModalInternal,
            countriesListPage
        );
    };

    const isDisabled = allInteractedBenchmarking.length === 0 && allInteractedCountries.length === 0;

    return (
        <>
            <MainWrapper>
                <LinkWrapper />
                <NameAndCTAs>
                    <h1>Countries</h1>
                    <SaveButton
                        disabled={isDisabled}
                        onClick={saveChanges}
                    >
                        <img src={iconSave} alt="add country icon" />
                        Save changes
                    </SaveButton>
                </NameAndCTAs>
                <FiltersAddAndSave>
                    <FiltersAndAdd>
                        <DropdownSingleSelection
                            labelText="Benchmarking"
                            isDisabled={false}
                            selectedValue={selectedBenchmarking}
                            setValue={setBenchmarking}
                            allValues={disabledEnabledDropdownValues}
                            width="10em"
                        />
                        <DropdownSingleSelection
                            labelText="Status"
                            isDisabled={false}
                            selectedValue={selectedStatus}
                            setValue={setStatus}
                            allValues={disabledEnabledDropdownValues}
                            width="10em"
                        />
                        <AddButton disabled={false} onClick={Router.goToCountryAdd}>
                            <img src={addIcon} alt="add country icon" />
                            Add country
                        </AddButton>
                    </FiltersAndAdd>
                    <SearchAndSave>
                        {exportSpinner ? (
                            <CircularProgress />
                        ) : (
                            <ExportButton onClick={() => exportCountryCSV(setExportSpinner, filters)}>
                                <img src={iconDownload} alt="download" />
                            </ExportButton>
                        )}
                        <SearchField placeholder="Search" callback={(value: string) => countriesSearchCallback(value)} />
                    </SearchAndSave>
                </FiltersAddAndSave>
                <TableOrSplash>
                    {loadingCountries ? (
                        <CircularProgress />
                    ) : (
                        <NewTable header={headerData} tablePagination={tablePagination} rows={tableRow} />
                    )}
                </TableOrSplash>
            </MainWrapper>
            <SuccessModal
                openState={openSuccessModal}
                hideDuration={6000}
                setOpen={setOpenSuccessModal}
                message={isModalInternal ? messages.COUNTRIES_SUCCESS_MESSAGE : messages.COUNTRY_SUCCESS_MESSAGE}
            />
        </>
    );
};

const SaveButton = styled(RedButton)`
    margin-left: 10px !important;
    width: 180px;
    font-size: 16px !important;
    img {
        padding-right: 3px;
    }
    height: 50%;
    margin-top: 0.67em !important;
`;

const MainWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

const NameAndCTAs = styled('div')`
    display: flex;
    justify-content: space-between;
    min-height: 100px;
    padding: 5px 0;

    h2 {
        margin: 0;
    }
`;

const FiltersAddAndSave = styled('div')`
    display: flex;
    justify-content: space-between;
`;

const FiltersAndAdd = styled('div')`
    display: flex;
`;

const SearchAndSave = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

const TableOrSplash = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const AddButton = styled(RedButton)`
    width: 10em;
    height: 2.7em;
    align-self: flex-end;
    color: white !important;
    img {
        margin-right: 0.5em;
    }
`;

const LinkWrapper = styled('div')`
    height: 46px;
`;
export default ManageCountries;
