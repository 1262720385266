import { Routes, DynamicRoutes } from './routes.enum';
import { createBrowserHistory } from 'history';
import { History } from 'history';

class Router {
    public history: History;

    constructor() {
        this.history = createBrowserHistory();
    }

    public goToMain = () => {
        this.history.push(Routes.main);
    };

    public goToAuditTrails = () => {
        this.history.push(Routes.auditTrails);
    };

    public getCurrentPath = () => {
        return this.history.location.pathname;
    };

    public goToWebApp = () => {
        window.location.href = window.__ENV__.REACT_APP_ASSESSMENT_URL || '';
    };

    public goToUserManagement = (isUserCreated:string = 'false') => {
        this.history.push(DynamicRoutes.goToUsers(isUserCreated));
    };

    public goToLogoutPage = () => {
        this.history.push(Routes.logoutRedirect);
    }

    public goToUser = ({
        userId,
        userName,
        userRole
    }:{userId:string,
        userName:string,
        userRole:string
    }) => {
       this.history.push(DynamicRoutes.goToUser(userId,userName,userRole));
    };

    public goToAddEditExternalUser = ({
        userId,
        addEdit
    }:{
        userId:string,
        addEdit:string
    }) => {
       this.history.push(DynamicRoutes.goToAddEditExternalUser(addEdit,userId));
    };

    public goToAddEditInternalUser = ({
        userId,
        addEdit
    }:{
        userId:string,
        addEdit:string
    }) => {
       this.history.push(DynamicRoutes.goToAddEditInternalUser(addEdit,userId));
    };

    public goToCountryManagement = (isCountryCreated: string = 'false') => {
        this.history.push(DynamicRoutes.goToCountries(isCountryCreated));
    };

    public goToCountry = ({
        countryId,
        countryName
    }:{countryId:string,
        countryName:string
    }) => {
       this.history.push(DynamicRoutes.goToCountry(countryId,countryName));
    };

    public goToCountryAdd = () => {
        this.history.push(Routes.addCountries);
    };

    public goToCountryEdit = ({
        countryId
    }:{
        countryId: string
    }) => {
        this.history.push(DynamicRoutes.goToCountryEdit(
            countryId
        ));
    };

    public goToCompaniesManagement=(isCompanyCreated: string = 'false')=>{
        this.history.push(DynamicRoutes.goToCompanies(isCompanyCreated));
    };

    public goToCompany = ({
        companyId,
        companyName
    }:{companyId:string,
        companyName:string
    }) => {
       this.history.push(DynamicRoutes.goToCompany(companyId,companyName));
    };

    public goToCompanyAdd = () => {
        this.history.push(Routes.addCompanies);
    };

    public goToCompanyEdit = ({
        companyId
    }:{
        companyId: string
    }) => {
        this.history.push(DynamicRoutes.goToCompanyEdit(
            companyId
        ));
    };

    public goToFrameworksManagement = (isFrameworkCreated: string = 'false') => {
        this.history.push(DynamicRoutes.goToFrameworks(isFrameworkCreated));
    };
    public goToManageFrameworks = () => {
        this.history.push(Routes.manageFrameworks);
    };

    public goToUploadFrameworks = () => {
        this.history.push(Routes.uploadFrameworks);
    };

    public goToFramework = ({
        frameworkId,
        frameworkName
    }:{frameworkId:string,
        frameworkName:string
    }) => {
       this.history.push(DynamicRoutes.goToFramework(frameworkId,frameworkName));
    };

    public goToManageCampaigns = () => {
        this.history.push(Routes.manageCampaigns);
    };

    public goToManageSettings = () => {
        this.history.push(Routes.manageSettings);
    };

    public goToNotificationHub = () => {
        this.history.push(Routes.notificationHub);
    };

    public goToReports = () => {
        this.history.push(Routes.Reports);
    };
}

export default new Router();
