import {
    Framework,
    FrameworkSummariesByCompany,
    SortHandler,
    UserListPage,
    UserSummaries
} from '../companyTypes';
import { SortDirection, TableCellTypes } from '../../../components/Table/types';
import {convertPermissionLabels} from '../../../utils/helpers'
import { MutationFunction } from '@apollo/client';

export const TabOptions = {
    FRAMEWORKS: 'Frameworks',
    USERS: 'Users'
};

export const setTableRowsWithFramework = (
    allSelectedFrameworks: number[],
    handleFrameworksToggleChange: (cellID: number) => void,
    frameworkSummaries?: FrameworkSummariesByCompany
) => {
    return (
        frameworkSummaries &&
        frameworkSummaries.items.map((item: Framework) => {
            return {
                tableCells: [
                    {
                        cellName: item.name,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: item.countryOwner,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: item.proposition,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        isCellSortable: false,
                        cellType: 'boolean' as TableCellTypes,
                        toggleLabels: {
                            trueLabel: 'Enabled',
                            falseLabel: 'Disabled'
                        },
                        currentState: allSelectedFrameworks.includes(item.frameworkId),
                        actionFunc: () => handleFrameworksToggleChange(item.frameworkId),
                        cellID: item.frameworkId
                    }
                ]
            };
        })
    );
};

export const setTableRowsWithUser = (
    allSelectedUsers: number[],
    allCreateCampaign: number[],
    handleUsersEnabledToggleChange: (cellID: number) => void,
    handleCreateCampaignToggleChange: (cellID: number) => void,
    userSummaries?: UserListPage
) => {
    return (
        userSummaries &&
        userSummaries.items.map((item: UserSummaries) => {
            return {
                tableCells: [
                    {
                        cellName: item.name,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: item.email,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: item.primaryCountry,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    // {
                    //     isCellSortable: false,
                    //     cellType: 'boolean' as TableCellTypes,
                    //     toggleLabels: {
                    //         trueLabel: 'Enabled',
                    //         falseLabel: 'Disabled'
                    //     },
                    //    currentState: allCreateCampaign.includes(item.id),
                    //    actionFunc: () => handleCreateCampaignToggleChange(item.id),
                    //     cellID: item.id,

                    // },
                    {
                        cellName: convertPermissionLabels(item.displayRole),
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },

                    {
                        isCellSortable: false,
                        cellType: 'boolean' as TableCellTypes,
                        toggleLabels: {
                            trueLabel: 'Enabled',
                            falseLabel: 'Disabled'
                        },
                        currentState: allSelectedUsers.includes(item.id),
                        actionFunc: () => handleUsersEnabledToggleChange(item.id),
                        cellID: item.id,
                        hideCell: item.role === 'EXTERNAL'
                    }
                ]
            };
        })
    );
};

export const getFrameworksTableHeader = (createSortHandler: SortHandler) => [
    {
        headerName: 'Framework name',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: ()=>createSortHandler('frameworkName')
    },
    {
        headerName: 'Country owner',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: ()=>createSortHandler('countryOwner')
    },
    {
        headerName: 'Proposition',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: ()=>createSortHandler('proposition')
    },
    {
        headerName: 'Framework access',
        isHeaderSortable: false
    }
];

export const getUsersTableHeader = (createSortHandler: SortHandler) => [
    {
        headerName: 'User name',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: ()=>createSortHandler('name')
    },
    {
        headerName: 'Email address',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: ()=>createSortHandler('email')
    },
    {
        headerName: 'PwC Office',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: ()=>createSortHandler('primaryCountry')
    },
    // {
    //     headerName: 'Create Campaign',
    //     isHeaderSortable: false
    // },
    {
        headerName: 'Permission',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: ()=>createSortHandler('role')
    },
    {
        headerName: 'User access',
        isHeaderSortable: false
    }
];

export const createTempFrameworksFomat = (allInteractedFrameworks: number[], allSelectedFrameworks: number[]) => {
    return allInteractedFrameworks.map((selFr: number) => {
        return {
            frameworkId: selFr,
            frameworkEnabled: allSelectedFrameworks.includes(selFr)
        };
    });
};

export const createTempUsersFomat = (allInteractedUsers: number[], allSelectedUsers: number[]) => {
    return allInteractedUsers.map((selUsr: number) => {
        return {
            id: selUsr,
            userEnabled: allSelectedUsers.includes(selUsr)
        };
    });
};

export const createTempCampaignFormat = (allInteractedCreateCampaign: number[], allCreateCampaign: number[]) => {
    return allInteractedCreateCampaign.map((selUsr: number) => {
        return {
            id: selUsr,
            createCampaign: allCreateCampaign.includes(selUsr)
        };
    });
};

export const saveHandler = (
    allInteractedFrameworks: number[],
    allSelectedFrameworks: number[],
    allInteractedUsers: number[],
    allSelectedUsers: number[],
    allCreateCampaign: number [],
    allInteractedCreateCampaign: number[],
    editCompany: MutationFunction,
    setOpenSuccessModal: Function,
    companyId: string,
    companyName: string,
    setAllInteractedFrameworks: (frameworkIds: number[]) => void,
    setAllInteractedUsers: (userIds: number[]) => void,
    setAllInteractedCreateCampaign: (userIds: number[]) => void,
) => {
    if (allInteractedFrameworks.length === 0 && allInteractedUsers.length === 0 && allInteractedCreateCampaign.length === 0)  {
        return;
    }

    editCompany({
        variables: {
            id: parseInt(companyId),
            data: {
                name: companyName,
                frameworks: createTempFrameworksFomat(allInteractedFrameworks, allSelectedFrameworks),
                users: createTempUsersFomat(allInteractedUsers, allSelectedUsers),
                campaigns: createTempCampaignFormat(allInteractedCreateCampaign,allCreateCampaign)
            }
        }
    })
        .then(() => {
            setAllInteractedFrameworks([]);
            setAllInteractedUsers([]);
            setAllInteractedCreateCampaign([])
            setOpenSuccessModal(true);
        })
        .catch((err: any) => {
            console.log(err);
        });
};
