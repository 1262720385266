import { gql } from 'apollo-boost';

export const COUNTRIES_LIST_MODEL = gql`
    query countrySummaries(
        $pagination: PaginationInput
        $search: String
        $filters: CountryFilters
    ) {
        countrySummaries(
            pagination: $pagination
            search: $search
            filters: $filters
        ) {
            items {
                id
                name
                isoCode
                territory
                gcpRegionId
                countryEnabled
                benchmarkingEnabled
                usersCount
                clientsCount
                frameworksCount
            }
            take
            skip
            total
            count
        }
    }
`;

export const COUNTRY_MODEL = gql`
    query country($countryId: ID!) {
        country(countryId: $countryId) {
            id
            name
            isoCode
            territory
            gcpRegionId
            countryEnabled
            benchmarkingEnabled
        }
    }
`;

export const FRAMEWORK_SUMMARIES_BYCOUNTRY_MODEL = gql`
    query frameworkSummariesByCountry(
        $countryId: Int!
        $pagination: PaginationInput
        $search: String
        $filters: CountryFrameworkFilters
    ) {
        frameworkSummariesByCountry(
            countryId: $countryId
            pagination: $pagination
            search: $search
            filters: $filters
        ) {
            items {
                frameworkId
                name
                countryOwner
                proposition
                frameworkEnabled
            }
            take
            skip
            total
            count
        }
    }
`;

export const USERS_BYCOUNTRY_MODEL = gql`
    query usersByCountry(
        $countryId: ID!
        $pagination: PaginationInput
        $search: String
        $filters: CountryUserFilters
    ) {
        usersByCountry(
            countryId: $countryId
            pagination: $pagination
            search: $search
            filters: $filters
        ) {
            items {
                id
                countryId
                name
                email
                role
                displayRole
                countryAdmin
                countryUserEnabled
                campaignCreateEnabled
            }
            take
            skip
            total
            count
        }
    }
`;

export const ADD_COUNTRIES = gql`
    mutation createCountry($data: CreateCountryInput!) {
        createCountry(data: $data) {
            id
            name
            isoCode
            territory
            gcpRegionId
            countryEnabled
            benchmarkingEnabled
        }
    }
`;

export const EDIT_COUNTRY = gql`
    mutation updateCountry($data: UpdateCountryInput!) {
        updateCountry(data: $data) {
            id
            name
            isoCode
            territory
            gcpRegionId
            countryEnabled
            benchmarkingEnabled
        }
    }
`;

export const DROPDOWN_VALUES = gql`
    query dropdown_values {
        territories {
            territory
            region
        }
        gcpRegions {
            id
            name
        }
    }
`;

export const USERS_BY_COUNTRY = gql`
    query usersByCountry(
        $countryId: ID!
        $pagination: PaginationInput
        $search: String
        $filters: CountryUserFilters
    ) {
        usersByCountry(
            countryId: $countryId
            pagination: $pagination
            search: $search
            filters: $filters
        ) {
            items {
                id
                countryId
                name
                email
                role
                displayRole
                countryAdmin
                countryUserEnabled
                campaignCreateEnabled
            }
            take
            skip
            total
            count
        }
    }
`;

export const ALL_USERS = gql`
    query userSummaries(
        $pagination: PaginationInput
        $search: String
        $filters: UserFilters
        $enabledCountryUserIds: [Int!]
    ) {
        userSummaries(
            pagination: $pagination
            search: $search
            filters: $filters
            enabledCountryUserIds: $enabledCountryUserIds
        ) {
            items {
                id
                name
                countryIsoCode
                email
                userEnabled
                role
                displayRole
            }
            take
            skip
            total
            count
        }
    }
`;

export const FRAMEWORKS_BY_COUNTRY = gql`
    query frameworkSummariesByCountry(
        $countryId: Int!
        $pagination: PaginationInput
        $search: String
        $filters: CountryFrameworkFilters
    ) {
        frameworkSummariesByCountry(
            countryId: $countryId
            pagination: $pagination
            search: $search
            filters: $filters
        ) {
            items {
                frameworkId
                name
                countryOwner
                proposition
                frameworkEnabled
            }
            take
            skip
            total
            count
        }
    }
`;

export const ALL_FRAMEWORKS = gql`
    query frameworkSummaries(
        $pagination: PaginationInput
        $search: String
        $enabledCountryFrameworkIds: [Int!]
        $filters: FrameworkFilters
    ) {
        frameworkSummaries(
            pagination: $pagination
            search: $search
            enabledCountryFrameworkIds: $enabledCountryFrameworkIds
            filters: $filters
        ) {
            items {
                frameworkId
                name
                countryOwner
                proposition
                benchmarkingEnabled
                frameworkEnabled
            }
            take
            skip
            total
            count
        }
    }
`;

export const COUNTRY_DETAILS = gql`
    query country($countryId: ID!) {
        country(countryId: $countryId) {
            id
            name
            isoCode
            territory
            gcpRegionId
            countryEnabled
            benchmarkingEnabled
        }
    }
`;

export const EDIT_COUNTRIES = gql`
    mutation updateCountries($data: [UpdateCountriesInput!]!) {
        updateCountries(data: $data)
    }
`;

export const PROPOSITIONS = gql`
    query frameworkPropositions {
        frameworkPropositions
    }
`;
