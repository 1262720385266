export const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
];
export const campaignActions = [
    { id: 'edit', label: 'Edit campaign' },
    { id: 'add', label: 'Add assessment' }
];

export const campaignAnalysis = [
    { id: 'analysis', label: 'View Threat Analisis' },
    { id: 'benchmarking', label: 'View Benchmarking' },
    { id: 'comparison', label: 'View Comparison' },
    { id: 'risk', label: 'View Risk Dashboard' },
    { id: 'var', label: 'View VAR analyses' }
];

export const assessmentsActiveTypes = [
    { id: 'ACTIVE', label: 'Active assessments' },
    { id: 'ALL', label: 'All assessments' },
    // { id: 'CLOSED', label: 'Closed assessments' },
    { id: 'ARCHIVED', label: 'Archived assessments' }
];

export const assessmentsProcessTypes = [
    { id: 'ALL', label: 'All assessments' },
    { id: 'COMPLETED', label: 'Completed and closed assessments' },
    { id: 'CLOSED_INCOMPLETE', label: 'Incomplete and Completed assessments' },
    { id: 'NOT_STARTED', label: 'Not started assessments' },
    { id: 'IN_PROGRESS', label: 'In progress assessments' }
];

export const maturityHeaderRows = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Entity'
    },
    // {
    //     id: 'assessmentLead',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Assessment Lead'
    // },
    {
        id: 'capScored',
        numeric: true,
        disablePadding: false,
        label: 'Scored'
    },
    {
        id: 'capCovered',
        numeric: true,
        disablePadding: false,
        label: 'Moderated'
    },
    {
        id: 'lastUpdated',
        numeric: true,
        disablePadding: false,
        label: 'Last Updated'
    },
    {
        id: 'progress',
        numeric: true,
        disablePadding: false,
        label: 'Status'
    }
];

export const assessmentHeadersRows = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Entity'
    },
    {
        id: 'campaignTitle',
        numeric: false,
        disablePadding: false,
        label: 'Campaign Title'
    },
    {
        id: 'framework',
        numeric: false,
        disablePadding: false,
        label: 'Framework'
    },
    // {
    //     id: 'assessmentLead',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Assessment Lead'
    // },
    {
        id: 'capScored',
        numeric: true,
        disablePadding: false,
        label: 'Scored'
    },
    {
        id: 'capCovered',
        numeric: true,
        disablePadding: false,
        label: 'Moderated'
    },
    {
        id: 'lastUpdated',
        numeric: true,
        disablePadding: false,
        label: 'Last Updated'
    },
    {
        id: 'progress',
        numeric: true,
        disablePadding: false,
        label: 'Status'
    }
];

export const createCompaignStepsTitles = [
    'Enter campaign details',
    'Create entities',
    'Add members'
];

export const colorsList = [
    '#D04A02',
    '#EB8C00',
    '#FFB600',
    '#DB536A',
    '#E0301E',
    '#464646',
    '#9013FE',
    '#0089EB',
    '#4EB523',
    '#FD6412',
    '#FFA929',
    '#FFC83D',
    '#E27588',
    '#E86153',
    '#7D7D7D',
    '#B15AFE',
    '#4DACF1',
    '#86DB4F'
];

export const initDomain = {
    id: '',
    name: '',
    capabilities: [
        {
            id: '',
            name: '',
            inScope: true
        }
    ],
    feedback: [
        {
            id: '',
            observations: [
                {
                    text: ''
                }
            ],
            recommendations: [
                {
                    text: ''
                }
            ],
            title: ''
        }
    ]
};

export const privacyBannerMessage='Data privacy notice: Do not enter any sensitive personal information in free text fields, including, but not limited to, that revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, health or sexual orientation.';

export enum GlobalSettingsStatus {
    READ_ONLY = 'Read Only',
    EXPORT_BUTTON = 'Export Button',
    TARGET_SCORE = 'Target Scoring',
    DECIMAL_POINT_ONE = 'Decimal .1',
    POWER_BI_REPORTS = 'Power BI Reports',
    CES_SEARCH = 'CES Search',
    NOTIFICATIONS = 'Notifications',
    IMPORT_BENCHMARKING = 'Import Benchmarking'
}

export const targetScoreDisableTitle='Switch Target scoring off?';
export const targetScoreDisableMessage='This action will hide the target scoring functionality and target scores for all campaign members. If target scoring is turned on again later, any existing target scores will be restored';
export const targetScoreEnableTitle='Overwrite existing target scores?';
export const targetScoreEnableMessage='This action will overwrite any existing target scores and cannot be undone.';

export const capabilityModeratedMessage='This capability has been moderated!';

export const autoSaveMessage='There are unsaved changes in the notes, do you want to proceed without saving?';

export const autoSaveFailedMessage='Auto save failed, please copy your notes and try saving it later';

export const noFrameworkPresetMessage='No frameworks found with this name'
