import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { List, ListItem } from '@mui/material';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import { CircularProgress } from '@mui/material';
import { UserStore } from '../../context/user-store';
import { useParams } from 'react-router';

import NewTable from '../../components/Table/Table';
import Router from '../../routes/router';
import addIcon from '../../assets/images/add-circle.svg';
import iconSave from '../../assets/images/icon-save.svg';
import iconDropdown from '../../assets/images/icon-drop-down-dark.svg';

import CustomMenu from '../../components/base/Dropdowns/CustomMenu';
import { TableRow as TableRowInter } from '../../components/Table/types';
import {
    RedButton,
    SearchField,
    DropdownSingleSelection,
    SuccessModal,
} from '../../components';
import { USERS_LIST_MODEL, LIST_COUNTRIES, UPDATE_USERS } from './userQueries';
import {
    UsersListModel,
    UsersListPage,
    Filters,
    UsersOrder,
    Country,
    UserSummary,
    UserMainProps,
} from './userTypes';
import {
    getHeaderData,
    saveHandler,
    setTableRows,
    disabledEnabledDropdownValues,
    getFilters,
    exportUserListCSV,
    userPermissionsValues,
} from './userHelpers';
import iconDownload from '../../assets/images/download-btn.svg';
import * as messages from '../../utils/Constant/messages';
import { dynamicSortWithForceTop } from '../../utils/helpers';
import FailureModal from '../../components/base/FailureModal/FailureModal';

const ManageUsers: React.FC = () => {
    const params = useParams<UserMainProps>();
    const {
        state: { user },
    } = useContext(UserStore);
    const isUserCreated = params.isUserCreated;
    const {
        loading: loadingAllCountries,
        error: errorAllCountries,
        data: dataAllCountries,
    } = useQuery(LIST_COUNTRIES, {
        variables: {
            pagination: {
                order: {
                    field: 'name',
                    isAsc: true,
                },
            },
        },
    });
    const { loading: loadingPrimaryCountries, data: dataPrimaryCountries } =
        useQuery(USERS_LIST_MODEL, {
            variables: {
                pagination: {
                    order: {
                        field: 'name',
                        isAsc: true,
                    },
                },
            },
        });
    const [editUsers] = useMutation(UPDATE_USERS);

    const [allCountries, setAllCountries] = useState<Country[]>([
        {
            id: 0,
            name: '',
        },
    ]);
    const [allPrimaryCountries, setAllPrimaryCountries] = useState<Country[]>(
        [],
    );
    const perpage = 60;
    const [selectedCountry, setSelectedCountry] = useState<string>('All');
    const [selectedPrimaryCountry, setSelectedPrimaryCountry] =
        useState<string>('All');
    const [selectedStatus, setStatus] = useState<string>('All');
    const [selectedPermission, setSelectedPermission] = useState<string>('All');
    const [page, setPage] = useState<number>(0);
    const [tableRow, setRow] = useState<TableRowInter[]>();
    const [filters, setFilters] = useState<Filters>({
        userEnabled: undefined,
        role: undefined,
        countryIds: undefined,
    });
    const [order, setOrder] = useState<UsersOrder | undefined>({
        field: 'name',
        isAsc: true,
    });
    const [searchText, setSearchText] = useState<string>('');
    const [usersListPage, setUsersListPage] = useState<UsersListPage>();
    const [exportSpinner, setExportSpinner] = useState<boolean>(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [isModalInternal, setIsModalInternal] = useState(true);

    const [isFailureModalInternal, setIsFailureModalInternal] = useState(true);
    const [openFailureModal, setOpenFailureModal] = useState(false);

    //Table states
    const [allSelectedUsers, setAllSelectedUsers] = useState<number[]>([]);
    const [allInteractedUsers, setAllInteractedUsers] = useState<number[]>([]);

    const [getUsers, { loading: loadingAllUsers }] =
        useLazyQuery<UsersListModel>(USERS_LIST_MODEL, {
            fetchPolicy: 'network-only',
            onCompleted: (res) => {
                setUsersListPage(res.userSummaries);

                const allSelected = res.userSummaries.items
                    .filter(
                        (item: UserSummary) =>
                            item.userEnabled &&
                            !allInteractedUsers.includes(item.id),
                    )
                    .map((item: UserSummary) => item.id);
                const allUniqueSelectedIds = Array.from(
                    new Set([...allSelected, ...allSelectedUsers]),
                );
                setAllSelectedUsers(allUniqueSelectedIds);
            },
            onError: (err) => {
                console.log(err);
            },
        });
    const createSortHandler = (property: string) => {
        if (order && order.field === property) {
            setOrder({ field: property, isAsc: !order.isAsc });
        } else {
            setOrder({ field: property, isAsc: true });
        }
    };

    useEffect(() => {
        if (isUserCreated === 'true') {
            setOpenSuccessModal(true);
            setIsModalInternal(false);
        }
    }, [isUserCreated]);

    useEffect(() => {
        if (loadingPrimaryCountries) {
            return;
        }
        const users: UserSummary[] =
            dataPrimaryCountries.userSummaries.items.reduce(
                (acc: UserSummary[], current: UserSummary) => {
                    const user = acc.find(
                        (item) =>
                            item.primaryCountry === current.primaryCountry,
                    );
                    if (!user && current.primaryCountry) {
                        return acc.concat([current]);
                    } else {
                        return acc;
                    }
                },
                [],
            );
        const primaryCountryList = users.map((item, id) => {
            return { id: id + 1, name: item.primaryCountry };
        });
        setAllPrimaryCountries([{ id: 0, name: 'All' }, ...primaryCountryList]);
    }, [dataPrimaryCountries, loadingPrimaryCountries]);

    useEffect(() => {
        if (loadingAllCountries || errorAllCountries) {
            return;
        }

        const countriesResponse = [...dataAllCountries.countrySummaries.items];

        countriesResponse.unshift({
            id: -1,
            name: 'All',
        });
        setAllCountries(countriesResponse);
    }, [dataAllCountries, loadingAllCountries, errorAllCountries]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleToggleActivateChange = (cellID: number) => {
        if (allSelectedUsers.includes(cellID)) {
            const arrWithoutCell = allSelectedUsers.filter(
                (selCoun: number) => selCoun !== cellID,
            );
            setAllSelectedUsers(arrWithoutCell);
        } else {
            setAllSelectedUsers([...allSelectedUsers, cellID]);
        }

        if (!allInteractedUsers.includes(cellID)) {
            setAllInteractedUsers([...allInteractedUsers, cellID]);
        }
    };

    useEffect(() => {
        if (loadingAllUsers) {
            return;
        }
        getUsers({
            variables: {
                filters,
                search: searchText,
                pagination: {
                    order: order,
                    skip: page * perpage,
                    take: perpage,
                },
            },
        });
    }, [page, filters, searchText, order]);

    useEffect(() => {
        if (!usersListPage) {
            return;
        }
        const rows = setTableRows(
            usersListPage,
            handleToggleActivateChange,
            allSelectedUsers,
            user.role,
        );

        setRow(rows);
    }, [usersListPage, allSelectedUsers, openSuccessModal]);

    useEffect(() => {
        getFilters(
            selectedCountry,
            allCountries,
            selectedPermission,
            selectedStatus,
            selectedPrimaryCountry,
            setPage,
            setFilters,
        );
    }, [
        selectedStatus,
        selectedPermission,
        selectedCountry,
        selectedPrimaryCountry,
    ]);

    const headerData = getHeaderData(createSortHandler);

    const tablePagination = {
        rowsPerPage: perpage,
        currentPage: page,
        handleChangePage,
        totalCount: usersListPage && usersListPage.total,
    };

    const userSearchCallback = (value: string) => {
        setSearchText(value);
        setPage(0);
    };

    const saveChanges = () => {
        saveHandler(
            allInteractedUsers,
            editUsers,
            allSelectedUsers,
            setOpenSuccessModal,
            setIsModalInternal,
            setAllInteractedUsers,
            setIsFailureModalInternal,
            setOpenFailureModal,
        );
    };

    return (
        <>
            <MainWrapper>
                <LinkWrapper />
                <NameAndCTAs>
                    <h1>Users</h1>
                    <SaveButton
                        disabled={allInteractedUsers.length === 0}
                        onClick={saveChanges}
                    >
                        <img src={iconSave} alt="add country icon" />
                        Save changes
                    </SaveButton>
                </NameAndCTAs>
                <FiltersAddAndSave>
                    <FiltersAndAdd>
                        <DropdownSingleSelection
                            labelText="Country"
                            isDisabled={false}
                            selectedValue={selectedCountry}
                            setValue={setSelectedCountry}
                            allValues={allCountries}
                            width="9em"
                        />
                        <DropdownSingleSelection
                            labelText="Permission"
                            isDisabled={false}
                            selectedValue={selectedPermission}
                            setValue={setSelectedPermission}
                            allValues={userPermissionsValues}
                            width="9em"
                        />
                        <DropdownSingleSelection
                            labelText="Status"
                            isDisabled={false}
                            selectedValue={selectedStatus}
                            setValue={setStatus}
                            allValues={disabledEnabledDropdownValues}
                            width="9em"
                        />
                        <DropdownSingleSelection
                            labelText="PwC office (country)"
                            isDisabled={false}
                            selectedValue={selectedPrimaryCountry}
                            setValue={setSelectedPrimaryCountry}
                            allValues={allPrimaryCountries.sort(
                                dynamicSortWithForceTop('name', 'All'),
                            )}
                            width="9em"
                        />
                        <CustomMenu
                            menuStyles={{
                                marginTop: '1.4em',
                                marginRight: '0.5em',
                            }}
                            top={'8%'}
                            buttonDisabled={false}
                            content={
                                <div>
                                    <List
                                        component="nav"
                                        aria-label="main mailbox folders"
                                    >
                                        <ListItem
                                            onClick={() =>
                                                Router.goToAddEditInternalUser({
                                                    userId: 'new',
                                                    addEdit: 'Add',
                                                })
                                            }
                                            button
                                            key={0}
                                        >
                                            PwC User
                                        </ListItem>
                                        <ListItem
                                            onClick={() =>
                                                Router.goToAddEditExternalUser({
                                                    userId: 'new',
                                                    addEdit: 'Add',
                                                })
                                            }
                                            button
                                            key={1}
                                        >
                                            External User
                                        </ListItem>
                                    </List>
                                </div>
                            }
                        >
                            {(buttonDisabled?: boolean) => (
                                <AddButton disabled={buttonDisabled}>
                                    <img src={addIcon} alt="add users icon" />
                                    Add user
                                    <img
                                        className="add-user-dropdown-icon"
                                        src={iconDropdown}
                                        alt="add user drodown"
                                        style={{
                                            filter: 'brightness(0) invert(1)',
                                        }}
                                    />
                                </AddButton>
                            )}
                        </CustomMenu>
                    </FiltersAndAdd>
                    <SearchAndSave>
                        {exportSpinner ? (
                            <CircularProgress />
                        ) : (
                            <ExportButton
                                onClick={() =>
                                    exportUserListCSV(setExportSpinner, filters)
                                }
                            >
                                <img src={iconDownload} alt="download" />
                            </ExportButton>
                        )}
                        <SearchField
                            placeholder="Search"
                            callback={(value: string) =>
                                userSearchCallback(value)
                            }
                        />
                    </SearchAndSave>
                </FiltersAddAndSave>
                <TableOrSplash>
                    {loadingAllUsers ? (
                        <CircularProgress />
                    ) : (
                        <NewTable
                            header={headerData}
                            tablePagination={tablePagination}
                            rows={tableRow}
                        />
                    )}
                </TableOrSplash>
            </MainWrapper>
            <SuccessModal
                openState={openSuccessModal}
                hideDuration={6000}
                setOpen={setOpenSuccessModal}
                message={
                    isModalInternal
                        ? messages.USERS_SUCCESS_MESSAGE
                        : messages.USER_SUCCESS_MESSAGE
                }
            />
            <FailureModal
                openState={openFailureModal}
                hideDuration={6000}
                setOpen={setOpenFailureModal}
                message={
                    isFailureModalInternal
                        ? messages.USERS_FAILURE_MESSAGE
                        : messages.USERS_FAILURE_MESSAGE
                }
            />
        </>
    );
};

const SaveButton = styled(RedButton)`
    margin-left: 10px !important;
    width: 180px;
    font-size: 16px !important;
    img {
        padding-right: 3px;
    }
    height: 50%;
    margin-top: 0.67em !important;
`;

const MainWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

const NameAndCTAs = styled('div')`
    display: flex;
    justify-content: space-between;
    min-height: 100px;
    padding: 5px 0;

    h2 {
        margin: 0;
    }
`;

const FiltersAddAndSave = styled('div')`
    display: flex;
    justify-content: space-between;
`;

const FiltersAndAdd = styled('div')`
    display: flex;
`;

const SearchAndSave = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

const TableOrSplash = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const AddButton = styled(RedButton)`
    width: 10em;
    height: 2.7em;
    align-self: flex-end;
    color: white !important;
    padding: 6px;
    img {
        margin-right: 0.3em;
    }
    .add-user-dropdown-icon {
        margin-left: 1.2em;
    }
`;

const LinkWrapper = styled('div')`
    height: 46px;
`;

const ExportButton = styled('div')`
    cursor: pointer;
    margin-bottom: 0.5em;
    margin-right: 1.5em;
`;
export default ManageUsers;
