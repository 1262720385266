import axios, { AxiosError } from 'axios';

import {
    Filters,
    ExportUsersByCompany,
    ExportFrameworksByCompany, CompanySummary, CompanyListPage, Country
} from './companyTypes';
import { SortDirection, TableCellTypes } from '../../components/Table/types';
import Router from '../../routes/router';
import { downloadFileFromData } from '../../utils/helpers';
import { MutationFunction } from '@apollo/client';

export const exportCompaniesCSV = (setSpinner: Function,params:Filters) => {
    setSpinner(true);
    const tkn = localStorage.getItem('pid-token');
    const clientLocal = localStorage.getItem('client');
    let clientUUID = null;
    if (clientLocal) {
        const clientParsed = JSON.parse(clientLocal);
        clientUUID = clientParsed.id;
    }
    axios
        .post(
            window.__ENV__.REACT_APP_APP_URL + '/admin/client/summaries/csv',
            {benchmarkingEnabled:params.benchmarkingEnabled,clientEnabled:params.clientEnabled},
            {
                headers: {
                    'pearl-client-uuid': clientUUID,
                    Authorization: `Bearer ${tkn}`
                }
            }
        )
        .then(response => {
            setSpinner(false);
            downloadFileFromData(`data:text/csv;charset=utf-8,${encodeURIComponent(response.data)}`, 'Companies Report.csv');
        })
        .catch(error => {
            setSpinner(false);
            console.log(error);
        });
};

export const exportUsersByCompanyCSV = (setSpinner: Function,params:ExportUsersByCompany) => {
    setSpinner(true);
    const tkn = localStorage.getItem('pid-token');
    const clientLocal = localStorage.getItem('client');
    let clientUUID = null;
    if (clientLocal) {
        const clientParsed = JSON.parse(clientLocal);
        clientUUID = clientParsed.id;
    }
    axios
        .post(
            window.__ENV__.REACT_APP_APP_URL + '/admin/client/'+params.companyId+'/user/summaries/csv',
            {userEnabled:params.userFilters.userEnabled,role:params.userFilters.role},
            {
                headers: {
                    'pearl-client-uuid': clientUUID,
                    Authorization: `Bearer ${tkn}`
                }
            }
        )
        .then(response => {
            setSpinner(false);
            downloadFileFromData(`data:text/csv;charset=utf-8,${response.data}`, 'UsersByCompany Report.csv');
        })
        .catch(error => {
            setSpinner(false);
            console.log(error);
        });
};
export const exportFrameworksByCompanyCSV = (setSpinner: Function,params:ExportFrameworksByCompany) => {
    setSpinner(true);
    const tkn = localStorage.getItem('pid-token');
    const clientLocal = localStorage.getItem('client');
    let clientUUID = null;
    if (clientLocal) {
        const clientParsed = JSON.parse(clientLocal);
        clientUUID = clientParsed.id;
    }
    axios
        .post(
            window.__ENV__.REACT_APP_APP_URL + '/admin/client/'+params.companyId+'/framework/summaries/csv',
            {frameworkEnabled:params.frameworkFilters.frameworkEnabled},
            {
                headers: {
                    'pearl-client-uuid': clientUUID,
                    Authorization: `Bearer ${tkn}`
                }
            }
        )
        .then(response => {
            setSpinner(false);
            downloadFileFromData(`data:text/csv;charset=utf-8,${response.data}`, 'FrameworksByCompany Report.csv');
        })
        .catch(error => {
            setSpinner(false);
            console.log(error);
        });
};


export const disabledEnabledDropdownValues = [
    {
        id:0,
        name:'All'
    },
    {
        id:1,
        name:'Disabled'
    },
    {
        id:2,
        name:'Enabled'
    }
];

export const adminDropdownValues = [
    {
        id:0,
        name:'All'
    },
    {
        id:1,
        name:'ADMIN'
    },
    {
        id:2,
        name:'STANDARD'
    },
    {
        id:3,
        name:'EXTERNAL'
    }
];

export const adminAddDropdownValues = [
    {
        id:0,
        name:'All'
    },
    {
        id:1,
        name:'ADMIN'
    },
    {
        id:2,
        name:'STANDARD'
    }
];

const handleCTA = (id: number, name: string) => {
    Router.goToCompany({ companyId: id.toString(), companyName: name });
};

export const setTableRows = (
    companiesListPage: CompanyListPage,
    handleToggleBenchmarkingChange: (cellID: number) => void,
    handleToggleActivateChange: (cellID: number) => void,
    allSelectedBenchmarking: number[],
    allSelectedCompanies: number[]
) => {
    return (
        companiesListPage &&
        companiesListPage.items.map((company: CompanySummary) => {
            return {
                tableCells: [
                    {
                        cellName: company.name,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: company.countryIsoCode,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: company.prId,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: company.industryName,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        isCellSortable: false,
                        cellType: 'boolean' as TableCellTypes,
                        toggleLabels: {
                            trueLabel: 'ON',
                            falseLabel: 'OFF'
                        },
                        currentState: allSelectedBenchmarking.includes(company.id),
                        actionFunc: () => handleToggleBenchmarkingChange(company.id),
                        cellID: company.id
                    },
                    {
                        isCellSortable: false,
                        cellType: 'boolean' as TableCellTypes,
                        toggleLabels: {
                            trueLabel: 'Enabled',
                            falseLabel: 'Disabled'
                        },
                        currentState: allSelectedCompanies.includes(company.id),
                        actionFunc: () => handleToggleActivateChange(company.id),
                        cellID: company.id
                    },
                    {
                        isCellSortable: false,
                        cellType: 'action' as TableCellTypes,
                        actionFunc: () => handleCTA(company.id, company.name)
                    }
                ]
            };
        })
    );
};

export const getFilters = (
    selectedCountry: string,
    allCountries: Country[],
    selectedStatus: string,
    selectedBenchmarking: string,
    setPage: (state: number) => void,
    setFilters: (state: Filters) => void
) => {
    const filter: Filters = {
        countryIds: undefined,
        clientEnabled: undefined,
        benchmarkingEnabled: undefined
    };

    if (selectedCountry !== 'All') {
        const chosenCountry = allCountries.find((selCountry: Country) => selCountry.name === selectedCountry);
        if (chosenCountry) {
            filter.countryIds = [chosenCountry.id];
        }
    }

    filter.clientEnabled = selectedStatus === 'Disabled' ? false : selectedStatus === 'Enabled' ? true : undefined;
    filter.benchmarkingEnabled =
        selectedBenchmarking === 'Disabled' ? false : selectedBenchmarking === 'Enabled' ? true : undefined;

    setPage(0);
    setFilters(filter);
};

export const getHeaderData = (
    createSortHandler: (property: string) => void
) => [
    {
        headerName: 'Company Name',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: ()=>createSortHandler('name')
    },
    {
        headerName: 'Country code',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: ()=>createSortHandler('countryIsoCode')
    },
    {
        headerName: 'PR ID',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: ()=>createSortHandler('prId')
    },
    {
        headerName: 'Industry',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: ()=>createSortHandler('industryName')
    },
    {
        headerName: 'Benchmarking',
        isHeaderSortable: false
    },
    {
        headerName: 'Status',
        isHeaderSortable: false
    },
    {
        headerName: 'View',
        isHeaderSortable: false
    }
];

export const editCountriesDTO = (
    allInteractedBenchmarking: number[],
    allInteractedCompanies: number[],
    allSelectedCompanies: number[],
    allSelectedBenchmarking: number[],
    companiesListPage?: CompanyListPage
) => {
    const mergedArray = [...allInteractedBenchmarking, ...allInteractedCompanies];
    const allUniqueInteractedRows = Array.from(new Set(mergedArray));

    return allUniqueInteractedRows.map((row: number) => {
        let companyEnabledValue;
        let benchmarkingEnabled;

        if (allInteractedCompanies.includes(row)) {
            companyEnabledValue = allSelectedCompanies.includes(row);
        } else {
            if (!!companiesListPage) {
                const companyEnabledObj = companiesListPage.items.find(
                    (company: CompanySummary) => company.id === row
                );
                companyEnabledValue = companyEnabledObj ? companyEnabledObj.clientEnabled : null;
            }
        }

        if (allInteractedBenchmarking.includes(row)) {
            benchmarkingEnabled = allSelectedBenchmarking.includes(row);
        } else {
            if (!!companiesListPage) {
                const benchmarkingObj = companiesListPage.items.find(
                    (company: CompanySummary) => company.id === row
                );
                benchmarkingEnabled = benchmarkingObj ? benchmarkingObj.benchmarkingEnabled : null;
            }
        }

        return {
            id: row,
            clientEnabled: companyEnabledValue,
            benchmarkingEnabled
        };
    });
};

export const saveHandler = (
    allInteractedBenchmarking: number[],
    allSelectedBenchmarking: number[],
    allInteractedCompanies: number[],
    allSelectedCompanies: number[],
    editCompanies: MutationFunction,
    setOpenSuccessModal: (state: boolean) => void,
    setAllInteractedBenchmarking:(benchmarkingIds: number[]) => void,
    setAllInteractedCompanies: (companyIds: number[]) => void,
    setIsModalInternal: Function,
    companiesListPage?: CompanyListPage
) => {
    if (allInteractedBenchmarking.length === 0 && allInteractedCompanies.length === 0) {
        return;
    }

    const countriesDTO = editCountriesDTO(
        allInteractedBenchmarking,
        allInteractedCompanies,
        allSelectedCompanies,
        allSelectedBenchmarking,
        companiesListPage
    );

    editCompanies({
        variables: {
            data: countriesDTO
        }
    })
        .then(() => {
            setAllInteractedBenchmarking([]);
            setAllInteractedCompanies([]);
            setIsModalInternal(true)
            setOpenSuccessModal(true);
        })
        .catch((err: AxiosError) => {
            console.log(err);
        });
};

export const searchCompanyInCES = (search: string, setResult: Function, setSearchError: Function, setIsCESLoading: Function) => {
    setIsCESLoading(true);
    const tkn = localStorage.getItem('pid-token');
    const clientLocal = localStorage.getItem('client');
    let clientUUID = null;
    if (clientLocal) {
        const clientParsed = JSON.parse(clientLocal);
        clientUUID = clientParsed.id;
    }
    if (search.length < 3) {
        setSearchError('Please type at least 3 letters for search.');
        setResult([]);
        return;
    }
    setSearchError('');
    axios.get(window.__ENV__.REACT_APP_APP_URL + '/ces/company', {
            params: {
                search
            },
            headers: {
                'pearl-client-uuid': clientUUID,
                'Authorization': `Bearer ${tkn}`
            }
        }
    ).then((response) => {
        if (response && response.status === 200) {
            if (response && response.data && response.data.message) {
                setResult([]);
                setSearchError(response.data.message);
            }
            if (response && response.data && response.data.length > 0) {
                setResult(response.data);
            }
        } else {
            setResult([]);
            setSearchError('Something went wrong');
        }
        setIsCESLoading(false);
    }).catch(error => {
        setResult([]);
        setSearchError(error);
        setIsCESLoading(false);
    });
};
