import { ExecutionResult } from 'graphql';

export type FrameworkMainProps= {
    isFrameworkCreated?: string;
 }

export type FrameworkDefinition={
    name: string;
    shortName: string;
    scores: string[];
    domains: any;
    metadata: any;
    supportsThreatAndRisk?: boolean;
    countryOwner?: string;
    proposition?: string;
    businessUnit?: string;
    hasObjectiveComponents?: boolean;
}

export type FrameworksSummary = {
    frameworkId: number;
    name: string;
    countryOwner: string;
    proposition: string;
    frameworkEnabled: boolean;
    benchmarkingEnabled: boolean;
};

export type FrameworksListModel = {
    frameworkSummaries: FrameworksListPage;
};

export type FrameworksListPage = {
    items: FrameworksSummary[];
    total: number;
    take: number;
    skip: number;
};

export type Filters = {
    benchmarkingEnabled?: boolean;
    frameworkEnabled?: boolean;
    proposition?:string;
};

export type FrameworkOrder = {
    field: string;
    isAsc: boolean;
};

export type GqlMutationType = (options?: {
    variables: Record<string, any>;
}) => Promise<ExecutionResult<any>>;

export type FrameworkDetailsProps = {
    frameworkName: string;
    frameworkId: string;
};

export type CountryListModel = {
    countrySummariesByFramework: CountryListPage;
};

export type CountryListPage = {
    items: CountrySummaries[];
    total: number;
    take: number;
    skip: number;
};
export type CountrySummaries = {
    id: number;
    countryIsoCode: string;
    frameworkEnabled: boolean;
    name: string;
};

export type ClientListModel = {
    clientSummariesByFramework: ClientListPage;
};

export type FrameworkSetting = {
    id: number,
    name: string;
    status: boolean;
};

export type ClientListPage = {
    items: ClientSummaries[];
    total: number;
    take: number;
    skip: number;
};

export type ClientSummaries = {
    id: number;
    countryIsoCode: string;
    frameworkEnabled: boolean;
    name: string;
};

export type FrameworkDetailFilters = {
    countryIds?: number[];
    frameworkEnabled?: boolean;
};

export type DropdownCountry={
    id: number;
    name: string;
    value: string;
};

export type ClientCSVParams={
    countryIds?: number[];
    frameworkEnabled?: boolean;
    frameworkId: string;
};

export type CountryCSVParams={
    countryIds?: number[];
    frameworkEnabled?: boolean;
    frameworkId: string;
};

export type CountryUserOverrides = {
    id: number;
    frameworkEnabled: boolean;
};


export const frameworkSchema = {
    type: 'object',
    properties: {
      id: { type: 'integer' },
      frameworkEnabled: { type: 'boolean' },
      benchmarkingEnabled: { type: 'boolean' },
      ownerId: { type: 'integer' },
      version: { type: 'string' }
    }
};

export type CreateFrameworkVersionInput={
    frameworkId: number;
    version?: string;
    definition: CreateFrameworkDefinition;
    ownerId: number;
    mapping?: MappingInput;
};

export type MappingInput={
    sourceVersionId: number;
    capabilities: { [key: string]: string | null };
}


export type CreateFrameworkInput= {
    definition: CreateFrameworkDefinition;
    id?: number;
    frameworkEnabled?: boolean;
    benchmarkingEnabled?: boolean;
    ownerId: number;
    version?: string;
}

export type CreateFrameworkDefinition={
    name: string;
    shortName: string;
    scores: string[];
    domains: DomainInput[];
    metadata: MetadataInput;
    supportsThreatAndRisk?: boolean;
    proposition?: string;
    countryOwner?: string;
    hasObjectiveComponents?: boolean;
}

export type DomainInput={
    name: string;
    shortName: string;
    capabilities: CapabilityInput[];
}

export type MetadataInput={
    frameworkNature: string;
    website: string;
    aidUrl: string;
    publishDate?: string;
    owner?: string;
}

export type CapabilityInput={
    name: string;
    shortName: string;
    category?: string;
    description?: string;
    objectives: ObjectiveInput[];
}

export type ObjectiveInput={
    title?: string;
    name: string;
    guidance: string;
    displayRef?: string;
    results?: string[];
    reference?: string;
    threatAndRiskType?: ThreatObjectiveTypeEnum;
    components?: ComponentInput[];
}

export enum ThreatObjectiveTypeEnum {
   P = 'P',
   C = 'C',
   D = 'D',
}
export type ComponentInput={
    name: string;
    question: string;
    results: string[];
    reference: string;
}